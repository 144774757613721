:root {
    --border-color: #3d3d3d;
}
.Project {
    border: solid 1px var(--border-color);
    display: inline-flex;
    flex-direction: column;
    width: 500px;
}


.Project #toolsUsed {
    padding: 10px;
    display: flex;
    align-items: center;
    font-style: normal;
    background-color: black;
    font-size: 14px;
    line-height: 16px;
}

.Project #tools {
    margin-left: 5px;
    display: flex;
    height: 100%;
    align-items: center;
}

.Project > #toolsUsed > #tools > a > div {
    padding-right: 5px;
}

.Project > #info > h3, .Project > #info > p {
    padding-left: 15px;
}

.Project > #info {
    min-height: 200px;
    width: 100%;
    position: relative;
    border-top: 1px solid var(--border-color);
}

.Project > #info > #darkOverlay {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.Project > #info > #infoText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 200px;
}

.Project > #info > #infoText * {
    overflow-wrap: break-word;
    padding: 0 10px 0 10px;
}

.Project > #info > #infoText > div {
    display: flex;
    align-items: baseline;
}
.Project > #info > #infoText > div > pre {
    color: #b4b4b4;
    font-size: 12px;
    padding-left: unset;
    text-shadow: -1px 2px 14px black;
}
.Project > #info > #infoText > p {
    font-weight: 300;
    font-size: 15px;
}

.Project > #links > a:not(:last-child) {
    border-right: solid 1px var(--border-color);
}

.Project >#links {
    border-top: 1px solid var(--border-color);
}
.Project > #links > a {
    width: 100%;
    text-align: center;
}

.Project > #links > a > div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Project > #links > a:hover * {
    color: black !important;
    background-color: #f1f1f1;
}


@media only screen and (max-width: 840px){
    .Project {
        width: 100% !important;
    }
}