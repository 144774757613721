.contact-form {
    width: 100%;
}

.contact-form textarea, input {
    resize: vertical;
    width: 100%;
    border: 1px solid var(--border-color);
    background-color: black;
    font-size: 15px;
    padding: 10px;
}

.contact-form input[type="submit"] {
    width: 100%;
    background-color: black;
    border-width: 1px;
    border-color: var(--border-color);
    border-style: solid;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 10px;
}

.contact-form input[type="submit"]:hover {
    color: black;
    background-color: white;
}

.contact-form input[type="submit"]:disabled {
    color: #61dafb;
    background-color: black;
}