.content {
    margin: 2rem;
}

.m-checkbox {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}
.m-checkbox__label {
    flex-shrink: 0;
    padding: 0.5rem 1rem;
    color: #fff;
    cursor: pointer;
}
.m-checkbox__input {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background: #252224;
    cursor: pointer;
    border: 2px solid var(--nav-color);
}
.m-checkbox__input:checked {
    border: 2px solid #5dceee;
}
.m-checkbox__input::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    transition: all 0.1s;
    background: #5dceee;
}
.m-checkbox__input:checked::before, .m-checkbox__input:indeterminate::before {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
}
.m-checkbox__input:indeterminate::before {
    top: 7px;
    bottom: 7px;
}
.m-checkbox__input:disabled {
    border-color: #5a5358;
    cursor: default;
}
.m-checkbox__input:disabled::before {
    background-color: #5a5358;
}
.m-checkbox__input:disabled + .m-checkbox__label {
    color: gray;
    cursor: default;
}
.m-checkbox--has-error__input {
    border-color: red;
    background-color: rgba(255, 0, 0, 0.2);
}
.m-checkbox--has-error__input::before {
    background-color: red;
}
.m-checkbox--switch__label::after {
    content: "off";
    margin-left: 0.25rem;
}
.m-checkbox--switch__input {
    width: 36px;
    height: 20px;
    border: 2px solid #5a5358;
    border-radius: 16px;
}
.m-checkbox--switch__input::before {
    top: 2px;
    right: 18px;
    bottom: 2px;
    left: 2px;
    border-radius: 50%;
    background: #5a5358;
}
.m-checkbox--switch__input:checked {
    border-color: #5dceee;
}
.m-checkbox--switch__input:checked + .m-checkbox--switch__label::after {
    content: "on";
}
.m-checkbox--switch__input:checked::before {
    right: 2px;
    left: 18px;
    background: #5dceee;
}