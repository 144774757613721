.about-container {
    display: flex;
    align-items: center;
    gap: 50px
}
.info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.info-container > div {
    flex-grow: 1;
}
.picture {
    width: 100%;
    height: auto;
    max-width: clamp(100px, 30vw, 400px);
    max-height: clamp(100px, 30vw, 400px);
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
#About {
    padding: 50px 100px 100px;
    display: flex;
    flex-direction: column;
}
#About > * { text-align: left; }
#iam {font-size: 40px; margin-bottom: 0; font-style: italic; padding-left: 5px}
#title {font-size: 120px; margin-top: 0; margin-bottom: 0}

#tools {
    display: flex;
    flex-wrap: wrap;
}

#tools .InfoBox {
    margin-right: 10px;
    margin-bottom: 10px;
}
#section-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}
#languages {
    column-width: 562px;
    flex-grow: 1;
}

#projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
}

#filter {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    position: relative;
    text-transform: capitalize;
    gap: 10px;
    margin-bottom: 30px;
    padding-bottom: 0.5rem;
}

/*Only show the scrollbar if hovered */
#filter::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em
}

#filter::-webkit-scrollbar-track {
    background-color: transparent;
}

#filter::-webkit-scrollbar-thumb {
    background-color: transparent;
}
#filter:hover::-webkit-scrollbar-track {
    background-color: rgba(133, 133, 133, 0.25);
}
#filter:hover::-webkit-scrollbar-thumb {
    background-color: var(--nav-color);
}

#filter > label {
    scroll-snap-align: start;
    border: solid 1px var(--border-color);
    display: flex;
    align-items: center;
    padding-left: 5px;
    user-select: none;
}
#filter > label:hover {
    border-color: var(--nav-color);
}

#socials {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

@media only screen and (max-width: 840px){
    .about-container {
        flex-wrap: wrap;
    }
    #About{
        padding: 145px 15px 15px;
    }
    #iam {font-size: 25px;}
    #title { font-size: 50px; }

    #wholeProgressBar {
        width: 200px !important;
    }
    #languages {
        padding-right: 0;
        column-width: 430px;
    }

    #projects > div {
        margin-right: 0;
    }
    #languages {
        max-height: unset;
    }
    #tools .InfoBox {
        margin-right: 0 !important;
    }

    .posts {
        min-width: 100%;
    }
}